.card_container {
  width: 70vw;
  background-color: hsl(216, 50%, 16%);
  margin: 10rem auto;
  border-radius: 15px;
  padding: 1.5rem;
  color:hsl(0, 0%, 100%);
}

.artwork {
  width: 100%;
    height: 300px;
    border-radius: 10px;
    position: relative;
}

.heading {
  padding: 1rem 0;
  font-weight: 400;
}

.description {
  font-weight: 200;
  color: hsl(215, 51%, 70%);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid hsl(215, 32%, 27%);
}

.authentication-house {
  width: 100%;
  text-align: center;
  color: hsl(178, 100%, 50%);
  font-size: 15px;
}

.wallet-address {
  font-size: small;
  text-align: center;
  width: 100%;
  margin-bottom: 0.2rem;
}
