@import url(https://fonts.googleapis.com/css?family=Outfit:200,400);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(217, 54%, 11%);
  font-family: 'Outfit', sans-serif;
  font-size: 18px
}
